@import "@angular/material/theming";
@include mat-core();
$schools-primary: mat-palette($mat-teal, 800);
$schools-accent: mat-palette($mat-grey, 600);
$schools-warn: mat-palette($mat-red);
$schools-theme: mat-light-theme(
  (
    color: (
      primary: $schools-primary,
      accent: $schools-accent,
      warn: $schools-warn,
    ),
  )
);
@include angular-material-theme($schools-theme);
html,
body {
  margin: 0 !important;
  padding: 0 !important;
}
button {
  outline: none !important;
}
